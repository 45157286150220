<template>
  <div class="order-detail-page">
    <Breadcrumb style="margin-bottom: 20px">
      <BreadcrumbItem to="/user/quotation">我的报价单</BreadcrumbItem>
      <BreadcrumbItem>报价详情</BreadcrumbItem>
    </Breadcrumb>
    <!-- 订单商品信息 -->
    <DetailOrderGoods  />
  </div>
</template>
<script>

import DetailOrderGoods from '@/views/member/order/quotation/components/DetailOrderGooods'

export default {
  name: 'QuotationOrderDetailPage',
  components: {
    DetailOrderGoods,
  },
  data () {
    return {}
  },
  setup () {

  },
}
</script>
<style scoped lang="less">
.order-detail-page {
  background: #fff;
  height: 100%;
}
</style>
